export const fengTheme = {
    name: 'feng',
    properties: {

        '--background': '#F8F8F8',
        '--on-background': '#27292D',
        '--primary': '#144C82',
        '--on-primary': '#F8F8F8',
        '--secondary': '#2331AF',
        '--on-secondary': '#F8F8F8',
        '--surface': '#F8F8F8',
        '--on-surface': '#11224F',
        '--error': '#F25455',
        '--on-error': '#fff',
        '--warning': '#EFC845',
        '--on-warning': '#fff',
        '--success': '#68DB6C',
        '--on-success': '#fff',
        '--info': '#D0D0D0',
        '--on-info': '#fff',
        '--primary-RGB': '65, 65, 65',
        '--primary-light': '#27292D',
        '--primary-dark': '#1B1B1B',
        '--secondary-RGB': '191,146,64',
        '--surface-RGB': '0, 0, 0',
        '--error-RGB': '255, 255, 255',
        '--warning-RGB': '255, 255, 255',
        '--success-RGB': '104,219,108',
        '--info-RGB': '255, 255, 255',

        '--max-width': '1200px',

        // Add external fonts @font-face to styles.scss
        '--font-primary': 'RidleyGrotesk-Medium',
        '--font-title': 'RidleyGrotesk-SemiBold',
        '--font-button': 'RidleyGrotesk-Medium',

        // Menu
        '--menu-itens-color': '#fff',
        '--menu-itens-background': 'transparent',
        '--menu-active-color': '#fff',
        '--menu-active-background': '#000',
        '--menu-active-underline': '0',

        '--menu-mobile-background': '#fff',
        '--menu-mobile-user-detail-color': '#404040',
        '--menu-mobile-user-detail-background': '#fff',
        '--menu-mobile-itens-color': '#404040',
        '--menu-mobile-itens-background': '#fff',

        // Header
        '--header-margin': '',
        '--header-bg': 'white',
        '--header-mobile-bg': 'white',
        '--header-mobile-logo-width': '100px',
        '--header-desktop-logo-width': '240px',
        '--header-mobile-height': '130px',
        '--header-desktop-height': '170px',
        '--header-mobile-font-size': '14px',
        '--header-desktop-font-size': '18px',
        '--header-mobile-margin-top': '0',
        '--header-desktop-margin-top': '0',
        '--header-mobile-logo-top': '-20px',
        '--header-desktop-logo-top': '-20px',
        '--header-desktop-content-padding': '0',
        '--public-header-security-top-padding': '0',
        '--private-header-security-top-padding': '0',
        '--header-logged-bg': '#144C82',
        '--header-logged-mobile-logo-width': '100px',
        '--header-logged-desktop-logo-width': '240px',

        // Home Public
        // Call to Action
        '--home-call-to-action-font-family': 'RidleyGrotesk-ExtraBold',
        '--home-call-to-action-background': 'no-repeat center right / cover',
        '--home-call-to-action-background-img': 'url(https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/bg-home.png)',
        '--home-call-to-action-detail-color': '#fff',
        '--home-call-to-action-title-font-size': '60px',
        '--home-call-to-action-title-font-family': 'RidleyGrotesk-ExtraBold',
        '--home-call-to-action-title-font-weight': 'bold',
        '--home-call-to-action-title-margin': '0 0 54px',
        '--home-call-to-action-title-line-height': '120%',
        '--home-call-to-action-title-alternate-font-family': 'CityDMed',
        '--home-call-to-action-title-alternate-font-size': '110px',
        '--home-call-to-action-subtitle-font-size': '21px',
        '--home-call-to-action-subtitle-width': '400px',
        '--home-call-to-action-content-padding': '12% 2%',
        '--home-call-to-action-content-align': 'left',
        '--home-call-to-action-button-margin': '0',
        '--home-call-to-action-button-background': 'var(--secondary)',
        '--home-call-to-action-button-padding': '8px 30px',
        '--home-call-to-action-button-font-size': '19px',
        '--home-call-to-action-button-style': 'normal',
        '--mobile-home-call-to-action-title-margin': '0 0 30px',
        '--mobile-home-call-to-action-content-padding': '0 10% 50px',
        '--mobile-home-call-to-action-title-font-size': '40px',
        '--mobile-home-call-to-action-title-line-height': '120%',
        '--mobile-home-call-to-action-title-alternate-font-family': 'CityDmed',
        '--mobile-home-call-to-action-title-alternate-font-size': '62px',
        '--call-to-action-margin-top': '0',
        '--call-to-action-padding-top': '30px',
        // Tabs
        '--home-desktop-tabs-1-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-1-background-img': '#F8F8F8',
        '--home-desktop-tabs-2-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-2-background-img': '#F8F8F8',
        '--home-desktop-tabs-3-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-3-background-img': '#F8F8F8',

        // Home Private
        '--home-private-padding-top': '0',
        '--home-private-background-texture': 'url(https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/mc-texture.svg) no-repeat center center / cover',

        // Plans
        '--plans-background': 'linear-gradient(180deg, var(--primary), #202020)',

        // Contact
        '--contact-background': 'url() no-repeat center center / cover',

        // Experiences
        '--experience-how-to-score-icon-filter': '',

        // Exclusive Content
        '--exclusive-content-background-RGB': '0,0,0',
        '--exclusive-content-background': 'white',
        '--exclusive-content-text': 'var(--primary)',

        // Events
        '--events-color-green-light': '#45ae6f',
        '--events-color-green-moss': '#01492C',
        '--events-color-red-light': '#E53249',
        '--events-color-gray-light': '#E7E7E7',
        '--events-border-gray-medium': '#979797',

        // Footer
        '--footer-item-margin': '25px auto',
        '--footer-color': 'white',
        '--footer-background': '#144C82',
        '--footer-logo-width': '240px',

        // Auth Modal
        '--auth-modal-stlogo-width': '180px',
        '--auth-modal-stlogo-bg': '#144C82',
        '--auth-modal-closebtn-color': 'black',
        '--mobile-auth-modal-closebtn-color': 'white',
        '--mobile-auth-modal-stlogo-width': '100px',
        '--mobile-auth-modal-stlogo-margin': '10px 0',
        '--first-access-bg': '#144C82',

        // Staging
        '--staging-logo-background-color': '#144C82',

        // Global Loading
        '--global-loading-background-color': 'white',
        '--global-loading-fill-color': 'white'
    },
    images: {
        'stLogo': 'https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/logo-maracan%C3%A3-light.png',
        'stLogoDeslogged': 'https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/logo-maracan%C3%A3-dark.png',
        'stLogoLogged': 'https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/logo-maracan%C3%A3-light.png',
        'stLogoMobile': 'https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/logo-maracan%C3%A3-dark.png',
        'stLogoFooter': 'https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/logo-maracan%C3%A3-light.png',
        'loadingLogo': 'https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/logo-maracan%C3%A3-dark.png',
        'stShield': 'https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/logo-maracan%C3%A3-dark.png',
        '--homePlansBG': 'url(https://fluminensefc.s3.amazonaws.com/prod/assets/images/bg-flu.png)',
        '--homeTicketsBG': 'url(https://fluminensefc.s3.amazonaws.com/prod/assets/images/bg-flu.png)',
        '--homeExclusiveContentBG': 'url(https://fluminensefc.s3.amazonaws.com/prod/assets/images/bg-flu.png)'
    },
    texts: {
        // Home
        // Call to Action
        'callToActionFeatured': 'MARACANÃ CLUB',
        'callToActionFeaturedImg': '',
        'callToActionDetail': 'Entre no clube e tenha acesso a conteúdos exclusivos, experiências únicas e muito mais!',
        'callToActionButton': 'Clique aqui',

        // Auth
        // First Access
        'firstAccessText': 'Identificamos que este é o seu primeiro acesso ao nosso novo sistema. Para continuar, você deve aceitar o Termos de Uso e Política de Privacidade. Aproveite também para atualizar os seus dados cadastrais!',
        // Recover Pass
        'recoverPassText': 'Para definir a sua nova senha, digite o código de recuperação recebido por e-mail, além de sua nova senha.',

        // Ticket Cards
        'ticketCardText': 'Cartão de Sócio',
        'ticketCardsText': 'Cartões de Sócio'
    }
}
